import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-mentions',
  templateUrl: './mentions.component.html',
  styleUrls: ['./mentions.component.scss']
})
export class MentionsComponent implements OnInit {
  year: any = new Date().getFullYear();

  constructor() { }

  ngOnInit() {
    $("#openModalMentions").on("click", function () {
      $("#mentions_modal").fadeIn();
      $(".close").off().on("click", function () {
        $("#mentions_modal").fadeOut();
      });
    });
  }

}
