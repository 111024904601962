import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-upbtn',
  templateUrl: './upbtn.component.html',
  styleUrls: ['./upbtn.component.scss']
})
export class UpbtnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(".upbtn").click(function () {
      $("html, body").animate({ scrollTop: 0 }, "slow");
    });
    $(window).scroll(function (event) {
      var scroll = $(window).scrollTop();
      if(scroll == 0){
        $('.upbtn').hide();
      }else{
        $('.upbtn').show();
      }
    });
  }
}
