import { Component, OnInit } from '@angular/core'; import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailService } from './email.service';
declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private emailService: EmailService) { }
  
  ngOnInit() {
      $("#openModalContact").on("click", function () {
      $("#contact_modal").fadeIn();
      $(".close").off().on("click", function () {
        $("#contact_modal").fadeOut();
      });
    });
    this.registerForm = this.formBuilder.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [ Validators.minLength(10)]],
      message: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.emailService.sendEmail(this.registerForm.value)
      .subscribe(
        res => {
          if (res == 'SEND') {
            $(".error").html('');
            $(".succes").html('Votre email a bien été transmis !');
            $('.formul').children().val('');
          } else {
            $(".succes").html('');
            $('.error').html('Une erreur est survenue dans l\'envoi de l\'email');
          }
        },
        (err) => { 
          $(".succes").html('');
          $('.error').html('Une erreur est survenue dans l\'envoi de l\'email');
        }
      );
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

}
