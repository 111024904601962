import { Component, OnInit } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver'; 
import { HttpClient } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit {


  // // constructor(private fileService: FileService) { }
  // constructor(private _http: HttpClient, private _FileSaverService: FileSaverService) {
  // }

  // onSave() {
  //   this._http.get('demo.pdf', {
  //     responseType: ResponseContentType.Blob // This must be a Blob type
  //   }).subscribe(res => {
  //     this._FileSaverService.save((<any>res)._body, fileName);
  //   });
  // }
  ngOnInit() {
    $("#myBtn2").on("click", function () {
      $("#myModal2").fadeIn();
      $(".close2").off().on("click", function () {
        $(".modal").fadeOut();
      });
    });
    //$("#myModal2").fadeIn();
  }

  // onDownloadPdf(pdfFilename: string) {
  //   //this.fileService.downloadFile().subscribe(response => {
  //   this.fileService.downloadFile().subscribe((response: any) => { //when you use stricter type checking
  //     let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
  //     const url = window.URL.createObjectURL(blob);
  //     //window.open(url);
  //     //window.location.href = response.url;
  //     fileSaver.saveAs(blob, 'employees.json');
  //     //}), error => console.log('Error downloading the file'),
  //   }), (error: any) => console.log('Error downloading the file'), //when you use stricter type checking
  //     () => console.info('File downloaded successfully');
  // }

}
