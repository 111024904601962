<div class="jeux" id="myBtn2">
    <div class="jeuxperso"></div>
    <div class="jeuxtext noselect">Retrouve tes&nbsp;jeux et&nbsp;tes&nbsp;activités </div>
    <div class="bandeau noselect">en cliquant ici</div>
</div>

<!-- The Modal2 -->
<div id="myModal2" class="modal">

    <!-- Modal content -->
    <div class="modal-content2">
        <span class="close2 noselect">&times;</span>
        <div class="modalJeuxTitre"></div>
        <div class="modalJeuxIcone1"></div>
        <div class="modalJeuxIcone2"></div>
        <div class="modalJeuxIcone3"></div>
        <div class="modalJeuxIcone4"></div>
        <div class="modalJeuxIcone5"></div>
        <div class="modalJeuxIcone6"></div>
        <div class="modalJeuxIcone7"></div>
        <a fileSaver [method]="'GET'" [fileName]="'Pousse-pousse des émotions'" [url]="'assets/games/Pousse-pousse-des-emotions.pdf'">
            <div class="modalJeuxBtn1 noselect">Pousse-pousse<br />des émotions</div>
        </a>
        <a fileSaver [method]="'GET'" [fileName]="'Jeu des rencontres'" [url]="'assets/games/Jeu-des-rencontres.pdf'">
            <div class="modalJeuxBtn2 noselect">Jeu <br />des rencontres</div>
        </a>
        <a fileSaver [method]="'GET'" [fileName]="'Etiquettes bocal Cookie surprise'" [url]="'assets/games/recette-cookie.pdf'">
            <div class="modalJeuxBtn3 noselect">Etiquettes bocal Cookie surprise</div>
        </a>
        <a fileSaver [method]="'GET'" [fileName]="'Conte du petit étourneau'" [url]="'assets/games/Conte-arbre-et-etourneau_320kb.mp3'">
            <div class="modalJeuxBtn4 noselect">Conte du petit étourneau</div>
        </a>
        <a fileSaver [method]="'GET'" [fileName]="'Bricolage de Pâques'" [url]="'assets/games/bricolage-paques-oeuf-poussin.pdf'">
            <div class="modalJeuxBtn5 noselect">Bricolage<br />de Pâques</div>
        </a>
        <a fileSaver [method]="'GET'" [fileName]="'Tuteurs à plantes'" [url]="'assets/games/Bricolage-tuteurs-oiseaux.pdf'">
            <div class="modalJeuxBtn6 noselect">Tuteurs<br />à plantes</div>
        </a>
        <a fileSaver [method]="'GET'" [fileName]="'Réponses aux jeux'" [url]="'assets/games/Reponse-jeux-atoutplus-2022-2023.pdf'">
            <div class="modalJeuxBtn7 noselect">Réponses<br />aux jeux</div>
        </a>
    </div>

</div>