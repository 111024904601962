import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(".header_button1").on("click", function () {
      $("#myModal iframe").attr('src', 'https://multimedia.editions-mediaclap.fr/redir.php?c=EVVPA&f=1');
      $("#myModal").fadeIn();
      $(".close").off().on("click", function () {
        $(".modal").fadeOut(400, function () { $(".modal-content").children("iframe").attr('src', ''); });
      });
    });

    $(".header_button2").on("click", function () {
      $("#myModal iframe").attr('src', 'https://multimedia.editions-mediaclap.fr/redir.php?c=EAFXT&f=1');
      $("#myModal").fadeIn();
      $(".close").off().on("click", function () {
        $(".modal").fadeOut(400, function () { $(".modal-content").children("iframe").attr('src', ''); });
      });
    });




    $(".header_button3").on("click", function () {
      window.location.href = "https://multimedia.editions-mediaclap.fr/download.php?f=medias/com/04-pdf/Bdc-Atoutplus.pdf";
    });
    
  }

}
