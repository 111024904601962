import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
// import { GtagModule } from 'angular-gtag';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgwWowModule } from 'ngx-wow';
import { FileSaverModule } from 'ngx-filesaver';


import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { ContentComponent } from './content/content.component';
import { FooterComponent } from './footer/footer.component';
import { MentionsComponent } from './mentions/mentions.component';
import { UpbtnComponent } from './upbtn/upbtn.component';
import { ModalComponent } from './modal/modal.component';
import { GamesComponent } from './games/games.component';
import { ContactComponent } from './contact/contact.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    MentionsComponent,
    UpbtnComponent,
    ModalComponent,
    ContactComponent,
    GamesComponent
  ],
  imports: [
    HttpClientModule,
    FormsModule,ReactiveFormsModule,
    BrowserModule,
    NgwWowModule, FileSaverModule,
    RouterModule.forRoot([]), AngularSvgIconModule.forRoot(),
    // GtagModule.forRoot({ trackingId: 'UA-12286648-10', trackPageviews: true }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
