<div class="_container">
    <div class="_row">
        <div class="logo noselect">Atout<span>plus</span></div>
        <div class="specimen noselect">Recevoir un spécimen gratuitement</div>
        <div class="header_buttons">
            <div class="header_button1 noselect">
                Vidéo de présentation
            </div>
            <div class="header_button2 noselect">
                Vidéo de témoignages
            </div>
            <div class="header_button3 noselect">
                Bon de commande
            </div>
        </div>
        <div class="tel noselect">02 41 53 19 88</div>
    </div>
</div>