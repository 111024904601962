<div id="contact_modal" class="modal">
    <div class="modal-content-load">
        <span class="close" (click)="onReset()">&times;</span>
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div id="contact" class="formul noselect">
                <div class="titreGeneral">Contact</div>
                <div class="_row">
                    <label class="formulaireCgu">Nom :</label>
                    <input type="text" formControlName="lastName" class="inputFormulaireCgu"
                        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback alert-1">
                        <div *ngIf="f.lastName.errors.required">vous n'avez pas renseigné votre nom</div>
                    </div>
                </div>
                <div class="_row">
                    <label class="formulaireCgu">Prénom :</label>
                    <input type="text" formControlName="firstName" class="inputFormulaireCgu"
                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback alert-1">
                        <div *ngIf="f.firstName.errors.required">vous n'avez pas renseigné votre prénom</div>
                    </div>
                </div>
                <div class="_row">
                    <label class="formulaireCgu">Email :</label>
                    <input type="text" formControlName="email" class="inputFormulaireCgu"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback alert-1">
                        <div *ngIf="f.email.errors.required">vous n'avez pas renseigné votre email</div>
                        <div *ngIf="f.email.errors.email">l'email renseigné n'est pas au bon format</div>
                    </div>
                </div>
                <div class="_row">
                    <label class="formulaireCgu">Téléphone :</label>
                    <input type="text" (keypress)="numberOnly($event)" formControlName="phone" class="inputFormulaireCgu" maxlength="10"/>
                    <!-- <input type="text" formControlName="phone" class="inputFormulaireCgu"
                        [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback alert-1">
                        <div *ngIf="f.phone.errors.required">vous n'avez pas renseigné votre téléphone</div>
                        <div *ngIf="f.phone.errors.pattern">vous n'avez pas renseigné votre téléphone</div>
                    </div> -->
                </div>
                <div class="_row">
                    <div class="formulaireCguM">
                        <label>Votre message :</label>
                        <textarea type="text" formControlName="message" class="textarea"
                            [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                        <div *ngIf="submitted && f.message.errors" class="invalid-feedback alert-2">
                            <div *ngIf="f.message.errors.required">vous n'avez pas renseigné votre message</div>
                        </div>
                    </div>
                </div>
                
                <button class="valider">envoyer</button>
                <div class="error"></div>
                <div class="succes"></div>
            </div>
            
        </form>
    </div>
</div>