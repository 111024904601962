<div class="main_container noselect">
    <!-- <div class="background_border"></div> -->
        <!-- <div class="container" id="container_0">
            <div class="row">
               
            </div>
        </div> -->



    <div class="container" id="container_1">
        <div class="row">
            <img class="background" src="assets/images/content-1-1-min.png">
            <h1><div class="title wow fadeIn">
                Pour les <div>écoles primaires</div>
            </div>
            <div class="subtitle_block">
                <div class="subtitle1 wow fadeIn">
                    <svg-icon src="assets/svg/title-left.svg"></svg-icon>
                    Agenda
                    <svg-icon src="assets/svg/title-right.svg"></svg-icon>
                </div>
                <div class="subtitle2 wow fadeIn">
                    <svg-icon src="assets/svg/title-left.svg"></svg-icon>
                    Carnet de liaison
                    <svg-icon src="assets/svg/title-right.svg"></svg-icon>
                </div>
                <div class="subtitle3 wow fadeIn">
                    <svg-icon src="assets/svg/title-left.svg"></svg-icon>
                    contenu ludique<br/>et culturel
                    <svg-icon src="assets/svg/title-right.svg"></svg-icon>
                </div>
                <div class="subtitle4 wow fadeIn">
                    <svg-icon src="assets/svg/title-left.svg"></svg-icon>
                    Personnalisable
                    <svg-icon src="assets/svg/title-right.svg"></svg-icon>
                </div>
            </div>
            </h1>
            <div class="wow fadeIn">
                <div class="description">
                    <svg-icon src="assets/svg/title-left.svg"></svg-icon>
                    <span>un outil 2 en 1, pratique et commun à tous !</span>
                    <svg-icon src="assets/svg/title-right.svg"></svg-icon>
                </div>
                <img class="image" src="assets/images/content-1-2-min.png">
            </div>
        </div>
    </div>
    <div class="container" id="container_2">
        <div class="row wow fadeInDown">
            <div class="content">
                <H2><div class="title">Un agenda scolaire</div>
                <div class="title2">
                    <svg-icon src="assets/svg/title-left.svg"></svg-icon>
                    <span>Plus</span> pédagogique <img src="assets/images/content-2-1-min.png"> <span>plus</span> simple d'utilisation
                    <svg-icon src="assets/svg/title-right.svg"></svg-icon>
                </div></H2>
            </div>
        </div>
    </div>
    <div class="container wow fadeIn" id="container_3">
        <div class="row">
            <img class="image image1 wow fadeIn" data-wow-offset="100" src="assets/images/content-3-1-min.png" alt="">
            <img class="image image2 wow fadeIn" data-wow-offset="100" src="assets/images/content-3-2-min.png" alt="">
            <div class="text1 wow fadeInLeftBig" data-wow-offset="100">Les dates écrites entièrement <br/>pour une lecture facilitée</div>
            <div class="text2 wow fadeInLeftBig" data-wow-offset="100"> 1 semaine = 1 double page </div>
            <div class="text3 wow fadeInLeftBig" data-wow-offset="100"> Des pages colorées, des blagues <br/> et des jeux pour soutenir le plaisir <br/> au quotidien </div>
            <div class="text4 wow fadeInRightBig" data-wow-offset="100">Des lignes espacées <br/> pour écrire les devoirs</div>
            <div class="text5 wow fadeInRightBig" data-wow-offset="100"> Un repère de couleur pour les <br/> mois, les périodes scolaires et <br/> des pictogrammes pour identifier <br/> les saisons </div>
            <div class="text6 wow fadeInRightBig" data-wow-offset="100">Des pages numérotées<br/>et des coins prédécoupés<br/> pour un accès rapide </div>
            <img class="arrow1 wow fadeIn" data-wow-offset="100" data-wow-delay="750ms" src="assets/images/content-3-arrow-1-min.png" alt="">
            <img class="arrow2 wow fadeIn" data-wow-offset="100" data-wow-delay="750ms" src="assets/images/content-3-arrow-2-min.png" alt="">
            <img class="arrow3 wow fadeIn" data-wow-offset="100" data-wow-delay="750ms" src="assets/images/content-3-arrow-3-min.png" alt="">
            <img class="arrow4 wow fadeIn" data-wow-offset="100" data-wow-delay="750ms" src="assets/images/content-3-arrow-4-min.png" alt="">
            <img class="arrow5 wow fadeIn" data-wow-offset="100" data-wow-delay="750ms" src="assets/images/content-3-arrow-5-min.png" alt="">
            <img class="arrow6 wow fadeIn" data-wow-offset="100" data-wow-delay="750ms" src="assets/images/content-3-arrow-6-min.png" alt="">
            <img class="arrow7 wow fadeIn" data-wow-offset="100" data-wow-delay="750ms" src="assets/images/content-3-arrow-2-min.png" alt="">
            <img class="arrow8 wow fadeIn" data-wow-offset="100" data-wow-delay="750ms" src="assets/images/content-3-arrow-2-min.png" alt="">
            <div class="button wow fadeInDown" id="agenda">feuilleter</div>
        </div>
    </div>
     <div class="container" id="container_4">
        <div class="row wow fadeInDown">
            <h2><div class="content">
                <div class="title">Un agenda scolaire <img src="assets/images/content-4-1-min.png"> carnet de liaison</div>
                <div class="title2">
                    <svg-icon src="assets/svg/title-left.svg" ></svg-icon>
                    <span>Plus</span> pratique au quotidien
                    <svg-icon src="assets/svg/title-right.svg"></svg-icon>
                </div>
            </div></h2>
        </div>
    </div>
     <div class="container" id="container_5">
        <div class="row">
            <!--<img style="display: block;    opacity: 0.5;   z-index: 30;position: absolute;top: -215px;" class="" src="assets/images/content-5.png" alt="">-->

            <img class="image1 " data-wow-offset="100" src="assets/images/content-5-2-min.png" alt="">
            <img class="fleche1 " data-wow-offset="100" src="assets/images/content-5-8-min.png" alt="">
            <div class="text1 ">
                <h3><span> 42 pages de correspondance</span></h3>
                <ul>
                    <li>prévues pour coller une feuille A5 sans découpage ou insérer un A5 sans dépasser</li>
                </ul>
            </div>
            <img class="image2" data-wow-offset="100" src="assets/images/content-5-3-min.png" alt="">
            <img class="fleche2 " data-wow-offset="100" src="assets/images/content-5-8-min.png" alt="">
            <h3><div class="text2 ">
                <span>3 pages <br/>absences</span>
            </div></h3>
            <img class="image3 " data-wow-offset="100" src="assets/images/content-5-4-min.png" alt="">
            <img class="fleche3 " data-wow-offset="100" src="assets/images/content-5-8-min.png" alt="">
            <h3><div class="text3 ">
                <span>2 pages <br/>retards</span>
            </div></h3>
            <img class="image4 " data-wow-offset="100" src="assets/images/content-5-5-min.png" alt="">
            <img class="fleche4 " data-wow-offset="100" src="assets/images/content-5-8-min.png" alt="">
            <h3><div class="text4 ">
                <span>une page<br/>renseignements <br/>de l’enfant</span>
            </div></h3>
            <img class="image5 " data-wow-offset="100" src="assets/images/content-5-6-min.png" alt="">
            <img class="fleche5 " data-wow-offset="100" src="assets/images/content-5-8-min.png" alt="">
            <h3><div class="text5 ">
                <span>Le calendrier<br/>de l'année scolaire</span>
            </div></h3>
            <img class="image6 " data-wow-offset="100" src="assets/images/content-5-7-min.png" alt="">
            <img class="fleche6 " data-wow-offset="100" src="assets/images/content-5-8-min.png" alt="">
            <h3><div class="text6 ">
                <span>une page<br/>autorisation<br/>de sortie</span>
            </div></h3>
        </div> 
    </div>
    <div class="container wow fadeIn" id="container_6">
        <div class="row">
            <img class="image wow fadeInLeftBig" data-wow-offset="100" src="assets/images/content-6-1-min.png" alt="">
            <div class="text wow fadeInRightBig">
                <h3><span> Une pochette de liaison</span></h3>
                <ul>
                    <li>intégrée au protège-cahier</li>
                    <li>pour transmettre des documents aux familles</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container" id="container_7">
        <div class="row">
            <img class="image wow fadeInLeftBig" data-wow-offset="100" src="assets/images/content-7-1-min.png" alt="">
            <h3><div class="text wow fadeInRightBig">
                <span> Une présentation <br/> de l’Association<br /> des Parents d’Élèves</span>
            </div></h3>
            <div class="button wow fadeInDown" id="parent">feuilleter</div>
        </div>
    </div>
    <div class="container" id="container_8">
        <div class="row wow fadeInDown">
            <h2><div class="content">
                <div class="title">Un agenda scolaire à l'image de l'établissement</div>
                <div class="title2">
                    <svg-icon src="assets/svg/title-left.svg"></svg-icon>
                    <span>Plus</span> personnalisé
                    <svg-icon src="assets/svg/title-right.svg"></svg-icon>
                </div>
            </div></h2>
        </div>
    </div>
    <div class="container wow fadeIn" id="container_9">
        <div class="row">
            <div class="content1">
                <div class="title">La couverture d’<span>ATOUT plus</span> est personnalisable par un signet inséré dans le
                    protège-cahier.</div>
                <img class="image wow fadeIn" data-wow-offset="100" src="assets/images/content-9-1-min.png" alt="">
                <img class="arrow1 wow fadeIn" data-wow-offset="100" data-wow-delay="750ms"
                    src="assets/images/content-9-arrow-1-min.png" alt="">
                <div class="text1 wow fadeInRightBig" data-wow-offset="100">logo DE L’école</div>
                <img class="arrow2 wow fadeIn" data-wow-offset="100" data-wow-delay="750ms"
                    src="assets/images/content-9-arrow-2-min.png" alt="">
                <div class="text2 wow fadeInLeftBig" data-wow-offset="100">photo <br/> de l’école</div>
                <img class="arrow3 wow fadeIn" data-wow-offset="100" data-wow-delay="750ms"
                    src="assets/images/content-9-arrow-3-min.png" alt="">
                <div class="text3 wow fadeInRightBig" data-wow-offset="100"> NOM DE L’école </div>
                <img class="arrow4 wow fadeIn" data-wow-offset="100" data-wow-delay="750ms"
                    src="assets/images/content-9-arrow-4-min.png" alt="">
                <div class="text4a wow fadeInLeftBig" data-wow-offset="100">Option :</div>
                <div class="text4 wow fadeInLeftBig" data-wow-offset="100">NOM, prénom<br />
                et classe de<br />
                l’enfant imprimés</div>
            </div>
            <div class="content2">
                <h3><div class="title wow fadeIn">PLANNING DE PERSONNALISATION</div></h3>
                <img class="image wow fadeIn" data-wow-offset="100" src="assets/images/content-9-2-min.png" alt="">
                <div class="subtitle">
                    <div class="text wow fadeIn" data-wow-offset="100">
                        <span>Fin juin :</span> date limite de commande et de réception des éléments <span class="description">logo</span><span class="description">photo</span>
                    </div>
                    <div class="text wow fadeIn" data-wow-offset="100">
                        <span>Début juillet :</span> date limite de validation du « Bon à Tirer »
                    </div>
                    <div class="text wow fadeIn" data-wow-offset="100">
                        <span>Mi-juillet :</span> départ en impression
                    </div>
                    <div class="text wow fadeIn" data-wow-offset="100">
                        <span>Fin août :</span> réception à l’école
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" id="container_10">
        <div class="row wow fadeInDown">
            <div class="content">
                <div class="title">Un agenda avec du contenu culturel, des activités, des blagues</div>
                <h2><div class="title2">
                    <svg-icon src="assets/svg/title-left.svg"></svg-icon>
                    <span>Plus</span> ludique
                    <svg-icon src="assets/svg/title-right.svg"></svg-icon>
                </div></h2>
            </div>
        </div>
    </div>
    <div class="container" id="container_11">
        <div class="row">
            <img class="image wow fadeIn" data-wow-offset="100" src="assets/images/content-11-1-min.png" alt="">
            <div class="subtitle">
                <div class="text1"><h3>Chaque semaine, l’enfant découvre un évènement important ou un personnage historique !</h3></div>
               <div class="text2"><h3>À chaque période de vacances, l’enfant retrouve une double-page d’activités dans son agenda !</h3></div>
            </div>
            <img class="image2 wow fadeIn" data-wow-offset="100" src="assets/images/content-11-2-min.png" alt="">
                <div class="text3"><h3>Des personnages rigolos sont à découvrir chaque semaine. Ils font des blagues <br/>et lancent des défis.</h3></div>
            <div class="title">
                <svg-icon src="assets/svg/title-left.svg"></svg-icon>
                    Chaque année, les contenus et les visuels sont renouvelés !
                <svg-icon src="assets/svg/title-right.svg"></svg-icon>
            </div>
        </div>
    </div>
    <div class="container" id="container_12">
        <div class="row wow fadeInDown">
            <div class="content">
                <h2><div class="title">Nos engagements</div></h2>
            </div>
        </div>
    </div>
    <div class="container" id="container_13">
        <div class="row">
            <img class="image1 wow fadeInLeftBig" data-wow-offset="100" src="assets/images/content-13-1-min.png" alt="">
            <img class="image2 wow fadeInRightBig" data-wow-offset="100" src="assets/images/content-13-2-min.png" alt="">
            <img class="image3 wow fadeIn" data-wow-offset="100" src="assets/images/content-13-3-min.png" alt="">
           <!-- <img class="image4 wow fadeInLeftBig" data-wow-offset="100" src="assets/images/content-13-4-min.png" alt="">-->
            <!-- <div class="content content1 wow fadeInLeftBig">
                <div class="title">PLUS <span>léger</span></div>
                <div class="subtitle">un poids optimisé = un cartable allégé !</div>
                <div class="text"><span class="text_title">atout<span>plus</span></span> ne contient que les jours <br />d’école de votre zone.</div>
            </div> -->
            <div class="content content2 wow fadeInRightBig">
                <h3><div class="title">Un agenda<br />PLUS <span>solide</span></div></h3>
                <div class="subtitle">pour durer toute l’année !</div>
                <div class="text">
                    Reliure collée et cousue<br />
                    Papier 100 gr offset blanc<br />
                    Couverture 300 gr<br />
                    Protège-cahier inclus.
                </div>
            </div>
            <div class="content content3 wow fadeInLeftBig">
                <h3><div class="title">Un agenda<br/>PLUS <span>engagé</span></div></h3>
                <div class="text"><span class="text_title">atout<span>plus</span></span> est assemblé<br />
                en ESAT par des travailleurs<br />
                en situation de handicap.<br />
                Il est imprimé et fabriqué en France.</div>
            </div>
            <div class="content content4 wow fadeInRightBig">
                <h3><div class="title">Un agenda<br />PLUS <span>écologique</span></div></h3>
                <div class="text"><span class="text_title">atout<span>plus</span></span> est imprimé avec des <br />encres végétales et des gammes<br />
                de papier PEFC (forêts gérées<br />
                durablement).
                <br/><br/>
                La pochette plastique<br />
                est recyclable !</div>
            </div>
            <div class="content content5 wow fadeInLeftBig">
                <h3><div class="title">Un agenda<br />PLUS <span>LOCAL</span></div></h3>
                <div class="text">Conçu par Médiaclap<br/>
                à Gennes-Val-de-Loire <br/>
                en Maine-et-Loire<br/>
                Imprimé par Offset 5 <br/>
                à la Mothe-Achard en Vendée<br/>
                Façonné par des ESAT <br/>
                de Maine-et-Loire.</div>
            </div>
        </div>
    </div>
    <div class="container" id="container_14">
        <div class="row wow fadeInDown">
            <div class="content">
                <div class="title">Témoignages</div>
            </div>
        </div>
    </div>
    <div class="container wow fadeIn" id="container_15">
        <div class="row">
            <div class="content_block">
                <div class="content content1 wow fadeInLeftBig">
                    <img class="image" data-wow-offset="100" src="assets/images/content-15-1-min.png" alt="">
                    <div class="text">
                        <img class="quot-left" data-wow-offset="100" src="assets/images/quot-left-min.png" alt="">
                        Les notices sur les événements<br/>
                        et les personnages historiques<br /> permettent de faire un exercice<br />
                        de lecture et un temps<br />d’échanges avec les élèves.
                        <img class="quot-right" data-wow-offset="100" src="assets/images/quot-right-min.png" alt="">
                    </div>
                </div>
                <div class="content content2 wow fadeInRightBig">
                    <img class="image" data-wow-offset="100" src="assets/images/content-15-2-min.png" alt="">
                    <div class="text">
                        <img class="quot-left" data-wow-offset="100" src="assets/images/quot-left-min.png" alt="">
                        Les enfants se sont<br /> bien appropriés l’outil.<br /> Ils en prennent soin.<br /> Ils ont beaucoup<br />
                        apprécié les doubles-<br />pages d’activités à<br /> chaque vacances !
                        <img class="quot-right" data-wow-offset="100" src="assets/images/quot-right-min.png" alt="">
                    </div>
                </div>
                <div class="content content3 wow fadeIn">
                    <img class="image" data-wow-offset="100" src="assets/images/content-15-3-min.png" alt="">
                    <div class="text">
                        <img class="quot-left" data-wow-offset="100" src="assets/images/quot-left-min.png" alt="">
                        La semaine de 4 jours sur une<br /> double-page fait l’unanimité :<br /> cela est plus pratique et<br />
                        permet d’avoir une vision<br />
                        globale.
                        <img class="quot-right" data-wow-offset="100" src="assets/images/quot-right-min.png" alt="">
                    </div>
                </div>
                <div class="content content4 wow fadeInLeftBig">
                    <img class="image" data-wow-offset="100" src="assets/images/content-15-4-min.png" alt="">
                    <div class="text">
                        <img class="quot-left" data-wow-offset="100" src="assets/images/quot-left-min.png" alt="">
                        Atoutplus est un outil de<br /> qualité qui correspond <br />à l’image de notre école<br /> grâce à la couverture<br />
                        personnalisable avec notre<br /> photo d’école et notre logo :<br /> c’est très important.
                        <img class="quot-right" data-wow-offset="100" src="assets/images/quot-right-min.png" alt="">
                    </div>
                </div>
                <div class="content content5 wow fadeInRightBig">
                    <img class="image" data-wow-offset="100" src="assets/images/content-15-5-min.png" alt="">
                    <div class="text">
                        <img class="quot-left" data-wow-offset="100" src="assets/images/quot-left-min.png" alt="">
                        Le fait qu’Atoutplus soit un outil 2<br /> en 1 (agenda et carnet de liaison)<br />
                        a été la motivation principale <br />pour notre choix. Cela permet un <br />vrai gain de temps pour tous !
                        <img class="quot-right" data-wow-offset="100" src="assets/images/quot-right-min.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
