<div class="_container wow fadeIn">
    <div class="_row noselect">
        <div class="logo noselect">Atout<span>plus</span></div>
        <img class="image1" src="assets/images/footer-1.png" alt="">
        <div class="text1" id="openModalContact"><u>Contactez-nous par mail</u>
        </div>

        <img class="image2" src="assets/images/footer-2.png" alt="">
        <div class="text2">Médiaclap<br />
            Z.I. Les Sabotiers<br />
            49350 GENNES-VAL-DE-LOIRE</div>
        <img class="image3" src="assets/images/footer-3.png" alt="">
        <div class="text3">02 41 53 19 88</div>
        <!-- <a href="https://www.mediaclap-edition.fr/" target="_blank"><img class="image4"
                src="assets/images/mediaclap-edition-2.png" alt=""></a> -->
        <div class="text4">
            Personnalisable,<div>ATOUT <span>plus</span></div> est un outil associant<br /> l’agenda scolaire et le cahier de liaison. Au<br /> même titre que les fournitures
            scolaires, cet outil <br />sera commun à tous et facilitera l’utilisation au <br />quotidien à l’école et à la maison. Cet outil a
            été<br /> imaginé, conçu et vendu par : </div>
        <div class="text5" id="openModalMentions">© <span [innerHTML]="year"></span> | Mentions légales</div>
    </div>
</div>