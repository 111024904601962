import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $("#parent").on("click", function () {
      $("#myModal iframe").attr('src', 'https://multimedia.editions-mediaclap.fr/redir.php?c=WAGUE&f=1');
      $("#myModal").fadeIn();
      $(".close").off().on("click", function () {
        $(".modal").fadeOut(400, function () { $(".modal-content").children("iframe").attr('src', ''); });
      });
    });
    $("#agenda").on("click", function () {
      $("#myModal iframe").attr('src','https://multimedia.editions-mediaclap.fr/redir.php?c=EQRNZ&f=1');
      $("#myModal").fadeIn();
      $(".close").off().on("click", function () {
        $(".modal").fadeOut(400, function () { $(".modal-content").children("iframe").attr('src', ''); });
      });
    });
  }
}
