<div id="mentions_modal" class="modal">
    <div class="modal-content-load">
        <span class="close">&times;</span>
        <div class="mentions">
            <div class="titreGeneral">Mentions légales</div>
            <div class="ligneVertical"></div>
            <div class="textCgu roboto_normal">
                <h2>Informations juridiques</h2>
                Groupe CÉPIA - SARL au capital de 33 000 €<br />
                Z.I. Les Sabotiers<br />
                49350 GENNES-VAL-DE-LOIRE<br />
                Tel : 02 41 53 27 62<br />
                Fax : 02 41 50 72 33<br />
                RCS 452 563 414 N° de Gestion 2004 B400 73<br />
                N° de Siret 452 563 414 00021<br />
                TVA intracommunautaire : FR72 45256341400021<br /><br />

                <h2>Hébergement</h2>
                OVH<br />
                Siège social : 2 rue Kellermann - 59100 Roubaix - France.<br /><br />

                <h2>Web design et crédit photos</h2>
                © Groupe CÉPIA <span [innerHTML]="year"></span><br />
            </div>

        </div>
    </div>
</div>